import {QollabPostMessage} from './types/index.d';
import {getId} from './message-id';
import {MenuItemsType} from './utils';

export type defaultNativeAppResponse = {
    code: 200 | 403 | 404 | 500;
    reason?: string;
    message?: 'OK';
};

export type getMePayload = {
    id: string;
    name: string;
    lastname: string;
    avatar: string;
    avatarThumb: string;
    phone: number;
    iin: string;
    status: string;
    user_type: string;
    sign: string;
};

export type getKeycloakTokenPayload = {
    accessToken: string;
    refreshToken: string;
    /** ISO format like 2024-07-29T23:06:31Z */
    expiresAt: string;
    environment: string;
    isAnonymous?: boolean;
};

export type nfcSupportedPayload = {
    isSupported: boolean;
};
export type nfcEnabledPayload = {
    isEnabled: boolean;
};
export type nfcReadTagPayload = {
    id: string;
};
export type biometricAccessPayload = {
    passed: boolean;
    method?: string;
};
export type sigurScanAvailablePayload = {
    isAvailable: boolean;
};
export type sigurRequestPermissionsPayload = {
    permissionsGranted: boolean;
};

export type locationServiceDefaultPayload = {
  success: boolean;
  reasons?: string[];
};

export type locationServiceStatusPayload = {
    status: 'running' | 'stopped';
};

/**
 * There are two possible kind of messages we can receive from native app:
 *     - RequestsFromNativeApp: native app initiates the dialog
 *     - ResponsesFromNativeApp: native app responds to a request initiated by the web
 */
type RequestsFromNativeApp = {
    NATIVE_EVENT: {
        type: 'NATIVE_EVENT';
        id: string;
        payload: {event: string};
    };
    SESSION_RENEWED: {
        type: 'SESSION_RENEWED';
        id: string;
        payload: {accessToken: string};
    };
};

export type SheetResponse = {
    action: 'SUBMIT' | 'DISMISS';
    result: Array<{
        id: string;
        selectedIds: Array<string>;
    }>;
};

export type ResponsesFromNativeApp = {
    SET_TITLE: {
        id: string;
        type: 'SET_TITLE';
        payload: defaultNativeAppResponse;
    };
    CUSTOM_BACK_ARROW_MODE: {
        id: string;
        type: 'CUSTOM_BACK_ARROW_MODE';
        payload: defaultNativeAppResponse;
    };
    SET_HEADER_MENU_ITEMS: {
        id: string;
        type: 'SET_HEADER_MENU_ITEMS';
        payload: defaultNativeAppResponse;
    };
    NAVIGATION_BAR: {
        id: string;
        type: 'NAVIGATION_BAR';
        payload: void;
    };
    GET_ME: {
        type: 'GET_ME';
        id: string;
        payload: getMePayload;
    };
    GET_KEYCLOAK_TOKEN: {
        type: 'GET_KEYCLOAK_TOKEN';
        id: string;
        payload: defaultNativeAppResponse | getKeycloakTokenPayload;
    };
    LOG_OUT: {
        type: 'LOG_OUT';
        id: string;
        payload: defaultNativeAppResponse;
    };
    CLOSE: {
        type: 'CLOSE';
        id: string;
        payload: defaultNativeAppResponse;
    };
    GET_QR: {
        type: 'GET_QR';
        id: string;
        payload: string;
    };
    COPY_TO_CLIPBOARD: {
        type: 'GET_QR';
        id: string;
        payload: defaultNativeAppResponse;
    };
    SHARE: {
        type: 'SHARE';
        id: string;
        payload: defaultNativeAppResponse;
    };
    SHARE_FILE: {
        type: 'SHARE_FILE';
        id: string;
        payload: defaultNativeAppResponse;
    };
    SET_BACKARROWVISIBLE: {
        type: 'SET_BACKARROWVISIBLE';
        id: string;
        payload: defaultNativeAppResponse;
    };
    SET_STORAGE: {
        type: 'SET_STORAGE';
        id: string;
        payload: defaultNativeAppResponse;
    };
    GET_STORAGE: {
        type: 'GET_STORAGE';
        id: string;
        payload: string;
    };
    CLEAR_STORAGE: {
        type: 'CLEAR_STORAGE';
        id: string;
        payload: defaultNativeAppResponse;
    };
    ERROR: {
        id: string;
        type: 'ERROR';
        payload: {code: number; reason: string};
    };
    ENABLE_NOTIFICATIONS: {
        type: 'ENABLE_NOTIFICATIONS';
        id: string;
        payload: defaultNativeAppResponse;
    };
    DISABLE_NOTIFICATIONS: {
        type: 'DISABLE_NOTIFICATIONS';
        id: string;
        payload: defaultNativeAppResponse;
    };
    ENABLE_SCREEN_CAPTURE: {
        type: 'ENABLE_SCREEN_CAPTURE';
        id: string;
        payload: defaultNativeAppResponse;
    };
    DISABLE_SCREEN_CAPTURE: {
        type: 'DISABLE_SCREEN_CAPTURE';
        id: string;
        payload: defaultNativeAppResponse;
    };
    OPEN_SETTINGS: {
        type: 'OPEN SETTINGS';
        id: string;
        payload: defaultNativeAppResponse;
    };
    OPEN_URL: {
        type: 'OPEN_URL';
        id: string;
        payload: defaultNativeAppResponse;
    };
    PWA: {
        type: 'PWA';
        id: string;
        payload: defaultNativeAppResponse;
    };
    NFC_SUPPORTED: {
        type: 'NFC_SUPPORTED';
        id: string;
        payload: nfcSupportedPayload;
    };
    NFC_ENABLED: {
        type: 'NFC_ENABLED';
        id: string;
        payload: nfcEnabledPayload;
    };
    NFC_READ_TAG: {
        type: 'NFC_READ_TAG';
        id: string;
        payload: nfcReadTagPayload;
    };
    NFC_STOP_READING: {
        type: 'NFC_STOP_READING';
        id: string;
        payload: defaultNativeAppResponse;
    };
    NFC_OPEN_SETTINGS: {
        type: 'NFC_OPEN_SETTINGS';
        id: string;
        payload: defaultNativeAppResponse;
    };
    BIOMETRIC_ACCESS: {
        type: 'BIOMETRIC_ACCESS';
        id: string;
        payload: biometricAccessPayload;
    };
    SIGUR_IS_SCAN_AVAILABLE: {
        type: 'SIGUR_IS_SCAN_AVAILABLE';
        id: string;
        payload: sigurScanAvailablePayload;
    };
    SIGUR_REQUEST_PERMISSIONS: {
        type: 'SIGUR_REQUEST_PERMISSIONS';
        id: string;
        payload: sigurRequestPermissionsPayload;
    };
    SIGUR_START_ACCESS: {
        type: 'SIGUR_START_ACCESS';
        id: string;
        payload: defaultNativeAppResponse;
    };
    SIGUR_STOP_ACCESS: {
        type: 'SIGUR_STOP_ACCESS';
        id: string;
        payload: defaultNativeAppResponse;
    };
    LOCATION_SERVICE_IS_READY: {
        type: 'LOCATION_SERVICE_IS_READY';
        id: string;
        payload: locationServiceDefaultPayload;
    }
    LOCATION_SERVICE_REQUEST_INDOOR_PERMISSION: {
        type: 'LOCATION_SERVICE_REQUEST_INDOOR_PERMISSION';
        id: string;
        payload: locationServiceDefaultPayload;
    }
    LOCATION_SERVICE_REQUEST_GPS_PERMISSION: {
        type: 'LOCATION_SERVICE_REQUEST_GPS_PERMISSION';
        id: string;
        payload: locationServiceDefaultPayload;
    }
    LOCATION_SERVICE_START: {
        type: 'LOCATION_SERVICE_START';
        id: string;
        payload: locationServiceDefaultPayload;
    }
    LOCATION_SERVICE_STOP: {
        type: 'LOCATION_SERVICE_STOP';
        id: string;
        payload: locationServiceDefaultPayload;
    }
    LOCATION_SERVICE_STATUS: {
        type: 'LOCATION_SERVICE_STATUS';
        id: string;
        payload: locationServiceStatusPayload;
    }
};

export type NativeAppResponsePayload<
    Type extends keyof ResponsesFromNativeApp,
> = ResponsesFromNativeApp[Type]['payload'];

type NativeAppRequestPayload<Type extends keyof RequestsFromNativeApp> =
    RequestsFromNativeApp[Type]['payload'];

type ResponseFromNative = ResponsesFromNativeApp[keyof ResponsesFromNativeApp];
type RequestFromNative = RequestsFromNativeApp[keyof RequestsFromNativeApp];

type RequestListener = (message: RequestFromNative) => void;
type ResponseListener = (message: ResponseFromNative) => void;
type MessageListener = RequestListener | ResponseListener;

const BRIDGE = '__orta_webview_bridge';

const hasAndroidPostMessage = () =>
    !!(
        typeof (window as any) !== 'undefined' &&
        (window as any).ortaWebView &&
        (window as any).ortaWebView.postMessage
    );

const hasWebKitPostMessage = () =>
    !!(
        typeof (window as any) !== 'undefined' &&
        (window as any).webkit &&
        (window as any).webkit.messageHandlers &&
        (window as any).webkit.messageHandlers.ortaWebView &&
        (window as any).webkit.messageHandlers.ortaWebView.postMessage
    );

/**
 * Maybe returns postMessage function exposed by native apps
 */
const getWebViewPostMessage = (): QollabPostMessage | null => {
    if (typeof (window as any) === 'undefined') {
        return null;
    }

    // Android
    if (hasAndroidPostMessage()) {
        return (jsonMessage) => {
            (window as any).ortaWebView!.postMessage!(jsonMessage);
        };
    }

    // iOS
    if (hasWebKitPostMessage()) {
        return (jsonMessage) => {
            (window as any).webkit!.messageHandlers!.ortaWebView!.postMessage!(
                jsonMessage,
            );
        };
    }

    return null;
};

let messageListeners: Array<MessageListener> = [];

const subscribe = (listener: MessageListener) => {
    messageListeners.push(listener);
};

const unsubscribe = (listener: MessageListener) => {
    messageListeners = messageListeners.filter((f) => f !== listener);
};

const isInIframe = () => {
    try {
        return (window as any).self !== (window as any).top;
    } catch (e) {
        return true;
    }
};

const isDisabledFromIframe = () => {
    if (typeof (window as any) === 'undefined') {
        return false;
    }

    if (!isInIframe()) {
        return false;
    }

    return !(window as any)?.frameElement?.hasAttribute(
        'data-enable-webview-bridge',
    );
};

let log: undefined | ((...args: Array<any>) => void) = undefined;

export const setLogger = (logger: typeof log): void => {
    log = logger;
};

/**
 * Returns true if there is a WebView Bridge installed
 */
export const isWebViewBridgeAvailable = (): boolean =>
    !isDisabledFromIframe() &&
    (hasAndroidPostMessage() || hasWebKitPostMessage());

/**
 * Send message to native app and waits for response
 */
export const postMessageToNativeApp = <T extends keyof ResponsesFromNativeApp>(
    {
        type,
        id = getId(),
        payload,
    }: {type: T; id?: string; payload?: Object | Array<MenuItemsType>},
    timeout?: number,
): Promise<NativeAppResponsePayload<T>> => {
    const postMessage = getWebViewPostMessage();
    const message = JSON.stringify({type, id, payload});
    log?.('[WebView Bridge] SEND:', message);

    if (!postMessage) {
        return Promise.reject({
            code: 500,
            reason: 'WebView postMessage not available',
        });
    }

    // ensure postMessage call is async
    setTimeout(() => {
        postMessage(message);
    });

    return new Promise((resolve, reject) => {
        let timedOut = false;

        const listener: ResponseListener = (response) => {
            log?.('[WebView Bridge] RECEIVED:', response);
            if (response.id === id && !timedOut) {
                if (response.type === type) {
                    log?.(
                        '[WebView Bridge] resolved id:',
                        response.id,
                        ' type:',
                        response.type,
                    );
                    resolve(response.payload);
                } else if (response.type === 'ERROR') {
                    reject(response.payload);
                } else {
                    reject({
                        code: 500,
                        reason: `bad type: ${response.type}. Expecting ${type}`,
                    });
                }
                unsubscribe(listener);
            }
        };

        subscribe(listener);

        if (timeout) {
            setTimeout(() => {
                log?.(
                    '[WebView Bridge] timeout id:',
                    id,
                    ' waited ms:',
                    timeout,
                );
                timedOut = true;
                unsubscribe(listener);
                reject({code: 504, reason: 'bridge timeout'});
            }, timeout);
        }
    });
};

/**
 * Initiates WebApp postMessage function, which will be called by native apps
 */
if (typeof (window as any) !== 'undefined') {
    (window as any)[BRIDGE] = (window as any)[BRIDGE] || {
        postMessage: (jsonMessage: string) => {
            log?.('[WebView Bridge] RCVD:', jsonMessage);
            let message: any;
            try {
                message = JSON.parse(jsonMessage);
            } catch (e) {
                throw Error(`Problem parsing webview message: ${jsonMessage}`);
            }
            messageListeners.forEach((f) => f(message));
        },
    };
}

export type NativeEventHandler = ({event}: {event: string}) => {
    action: 'default';
};

export const listenToNativeMessage = <T extends keyof RequestsFromNativeApp>(
    type: T,
    handler: (
        payload: NativeAppRequestPayload<T>,
    ) => Object | void | Promise<Object>,
): (() => void) => {
    const listener: RequestListener = (message) => {
        try {
            log?.('[WebView Bridge] RECEIVED:', JSON.stringify(message));
        } catch (e) {
            log?.('[WebView Bridge] RECEIVED but JSON PARSE ERR:', e);
        }

        if (message.type === type) {
            Promise.resolve(handler(message.payload)).then(
                (responsePayload) => {
                    const postMessage = getWebViewPostMessage();
                    if (postMessage) {
                        postMessage(
                            JSON.stringify({
                                type: message.type,
                                id: message.id,
                                payload: responsePayload,
                            }),
                        );
                    }
                },
            );
        }
    };

    subscribe(listener);

    return () => {
        unsubscribe(listener);
    };
};

export const onNativeEvent = (
    eventHandler: NativeEventHandler,
): (() => void) => {
    const handler = (payload: NativeAppRequestPayload<'NATIVE_EVENT'>) => {
        const response = eventHandler({
            event: payload.event,
        });

        return {
            action: response.action || 'default',
        };
    };

    return listenToNativeMessage('NATIVE_EVENT', handler);
};

export const onSessionRenewal = (
    handler: (payload: {accessToken: string}) => void,
): (() => void) => listenToNativeMessage('SESSION_RENEWED', handler);
