import {getKeycloakTokenPayload, postMessageToNativeApp} from './post-message';
import {getMePayload} from './post-message';
import {defaultNativeAppResponse} from './post-message';

export type GetKeycloakTokensMethodArgs =
    | {isFreshTokenNeeded: boolean; environment?: string}
    | undefined;

/**
 * Retrieves the user data.
 * @returns  A promise that resolves with defaultNativeAppResponse or an object containing the getMePayload.
 */
export const getMe = (): Promise<getMePayload> =>
    postMessageToNativeApp({
        type: 'GET_ME',
    });

export const logOut = (): Promise<defaultNativeAppResponse> =>
    postMessageToNativeApp({
        type: 'LOG_OUT',
    });

/**
 * Retrieves the Keycloak tokens.
 * Can be called as getKeycloakToken() or getKeycloakToken({isFreshTokenNeeded: true})
 * @param methodArgs - An optional argument to specify whether to always return a fresh token {isFreshTokenNeeded: boolean}.
 * @returns  A promise that resolves with defaultNativeAppResponse or an object containing the access token, refresh token, and expiration time in ISO format.
 */
export const getKeycloakToken = (
    args?: GetKeycloakTokensMethodArgs,
): Promise<defaultNativeAppResponse | getKeycloakTokenPayload> =>
    postMessageToNativeApp({
        type: 'GET_KEYCLOAK_TOKEN',
        ...(args &&
            Object.keys(args).length && {
                payload: {...args},
            }),
    });
