import {
	nfcEnabledPayload,
	nfcReadTagPayload,
	nfcSupportedPayload,
	biometricAccessPayload,
	postMessageToNativeApp,
	sigurScanAvailablePayload,
	sigurRequestPermissionsPayload,
	locationServiceDefaultPayload, locationServiceStatusPayload,
} from './post-message';
import { defaultNativeAppResponse } from './post-message';

// const TIMEOUT = 200;

/*
	Types
*/
type SetItemType = (keyName: string, keyValue: string) => Promise<defaultNativeAppResponse>;
type GetItemType = (keyName: string) => Promise<string>;
type ClearType = () => Promise<defaultNativeAppResponse>;

export type BridgeStorage = {
	setItem: SetItemType;
	getItem: GetItemType;
	clear: ClearType;
}

type BridgeNfcIsSupportedType = () => Promise<nfcSupportedPayload>;
type BridgeNfcIsEnabledType = () => Promise<nfcEnabledPayload>;
type BridgeNfcReadTagType = () => Promise<nfcReadTagPayload>;
type BridgeNfcStopReadingType = () => Promise<defaultNativeAppResponse>;
type BridgeNfcOpenSettingsType = () => Promise<defaultNativeAppResponse>;
export type BridgeNfc = {
	isSupported: BridgeNfcIsSupportedType;
	isEnabled: BridgeNfcIsEnabledType;
	readTag: BridgeNfcReadTagType;
	stopReading: BridgeNfcStopReadingType;
	openSettings: BridgeNfcOpenSettingsType;
}
type BiometricAccessAuthenticateType = () => Promise<biometricAccessPayload>;
export type BiometricAccessType = {
	authenticate: BiometricAccessAuthenticateType;
}
export type ScreenCaptureType = {
	enable: () => Promise<defaultNativeAppResponse>;
	disable: () => Promise<defaultNativeAppResponse>
}
export type NotificationsType = {
	enable: () => Promise<defaultNativeAppResponse>;
	disable: () => Promise<defaultNativeAppResponse>;
}
type BridgeSigurScanAvailableType = () => Promise<sigurScanAvailablePayload>;
type BridgeSigurRequestPermissionsType = () => Promise<sigurRequestPermissionsPayload>;
type BridgeSigurStartAccessType = () => Promise<defaultNativeAppResponse>;
type BridgeSigurStopAccessType = () => Promise<defaultNativeAppResponse>;
export type BridgeSigur = {
	isScanAvailable: BridgeSigurScanAvailableType;
	requestPermissions: BridgeSigurRequestPermissionsType;
	startAccess: BridgeSigurStartAccessType;
	stopAccess: BridgeSigurStopAccessType;
}

type BridgeLocationServiceIsReadyType = () => Promise<locationServiceDefaultPayload>;
type BridgeLocationServiceRequestIndoorPermissionsType = () => Promise<locationServiceDefaultPayload>;
type BridgeLocationServiceRequestGpsPermissionsType = () => Promise<locationServiceDefaultPayload>;
type BridgeLocationServiceStartType = () => Promise<locationServiceDefaultPayload>;
type BridgeLocationServiceStopType = () => Promise<locationServiceDefaultPayload>;
type BBridgeLocationServiceStatusType = () => Promise<locationServiceStatusPayload>;
export type BridgeLocationService = {
	isReady: BridgeLocationServiceIsReadyType;
	requestIndoorPermissions: BridgeLocationServiceRequestIndoorPermissionsType;
	requestGpsPermissions: BridgeLocationServiceRequestGpsPermissionsType;
	start: BridgeLocationServiceStartType;
	stop: BridgeLocationServiceStopType;
	status: BBridgeLocationServiceStatusType;
}
/*
	Methods
*/

/**
 * Scan QR code using the app.
 */
export const getQr = (): Promise<string> =>
	postMessageToNativeApp({
		type: 'GET_QR',
	});

/**
 * Copy text to clipboard of the app.
 * @param text - string to clipboard
 * @returns A promise that resolves with an object containing the response code and an optional message or reason.
 */
export const copyToClipboard = (text: string): Promise<defaultNativeAppResponse> =>
	postMessageToNativeApp({
		type: 'COPY_TO_CLIPBOARD',
		payload: {
			text,
		},
	});

/**
 * Share text using the native app
 * @param text - string to share
 * @returns A promise that resolves with an object containing the response code and an optional message or reason.
 */
export const share = (text?: string): Promise<defaultNativeAppResponse> =>
	postMessageToNativeApp({
		type: 'SHARE',
		payload: {
			text,
		},
	});

/**
 * Share files using the app.
 * @param {string} text - The string message that will be passed to the native app's share menu as a caption to the file.
 * @param {string} filename - The filename with extension.
 * @param {string} base64data - The file in base64 format.
 * @returns {Promise<{ code: number, message?: string, reason?: string }>} A promise that resolves with an object containing the response code and an optional message or reason.
 */
export const shareFile = (text: string, filename: string, based64data: string): Promise<defaultNativeAppResponse> =>
	postMessageToNativeApp({
		type: 'SHARE_FILE',
		payload: {
			text,
			filename,
			based64data
		},
	});


/**
 * Object contains methods for notifications.
 */
export const notifications: NotificationsType = {
	/**
	 * Enable notification only for the current mini application.
	 * @returns {Promise<{ code: number, message?: string, reason?: string }>} A promise that resolves with an object containing the response code and an optional message or reason.
	 */
	enable: () =>
		postMessageToNativeApp({
			type: 'ENABLE_NOTIFICATIONS',
		}),
	/**
	 * Disable notification only for the current mini application.
	 * @returns {Promise<{ code: number, message?: string, reason?: string }>} A promise that resolves with an object containing the response code and an optional message or reason.
	 */
	disable: () =>
		postMessageToNativeApp({
			type: 'DISABLE_NOTIFICATIONS',
		}),
}

/**
 * Object contains methods to enable or disable screen capture.
 */
export const screenCapture: ScreenCaptureType = {
	disable: () =>
		postMessageToNativeApp({
			type: 'DISABLE_SCREEN_CAPTURE',
		}),
	enable: () =>
		postMessageToNativeApp({
			type: 'ENABLE_SCREEN_CAPTURE',
		}),
}

/**
 * An object consisting of functions specifically crafted for managing application secured storage.
 */
export const storage: BridgeStorage = {
	/**
	 * Save key value pair items to native app's secure storage.
	 * @param {string} keyName - The key for the item.
	 * @param {string} keyValue - The value to be stored.
	 * @returns {Promise<{ code: number, message?: string, reason?: string }>} A promise that resolves with an object containing the response code and an optional message or reason.
	 */
	setItem: (keyName, keyValue) =>
		postMessageToNativeApp({
			type: 'SET_STORAGE',
			payload: {
				keyName,
				keyValue,
			}
		}),
	/**
	 * Get previously saved value by item's key from native app's secure storage.
	 * @param {string} keyName - The key of the item to retrieve.
	 * @returns {Promise<{ code: number, message?: string, reason?: string, value?: string }>} A promise that resolves with an object containing the response code and an optional message, reason, and value.
	 */
	getItem: (keyName) =>
		postMessageToNativeApp({
			type: 'GET_STORAGE',
			payload: {
				keyName,
			}
		}),
	/**
	 * Clear native app's secure storage.
	 * @returns {Promise<{ code: number, message?: string, reason?: string }>} A promise that resolves with an object containing the response code and an optional message or reason.
	 */
	clear: () =>
		postMessageToNativeApp({
			type: 'CLEAR_STORAGE',
		}),
}

/**
 * Accesses the settings menu of the native app.
 * @returns {Promise<{ code: number, message?: string, reason?: string }>} A promise that resolves with an object containing the response code and an optional message or reason.
 */
export const openSettings = (): Promise<defaultNativeAppResponse> => postMessageToNativeApp({
	type: 'OPEN_SETTINGS',
})

export const nfc: BridgeNfc = {
	isSupported: () =>
		postMessageToNativeApp({
			type: 'NFC_SUPPORTED'
		}),
	isEnabled: () =>
		postMessageToNativeApp({
			type: 'NFC_ENABLED'
		}),
	readTag: () =>
		postMessageToNativeApp({
			type: 'NFC_READ_TAG'
		}),
	stopReading: () =>
		postMessageToNativeApp({
			type: 'NFC_STOP_READING'
		}),
	openSettings: () =>
		postMessageToNativeApp({
			type: 'NFC_OPEN_SETTINGS'
		}),
}

export const biometricAccess: BiometricAccessType = {
	authenticate: () => postMessageToNativeApp({
		type: 'BIOMETRIC_ACCESS'
	})
}

export const sigur: BridgeSigur = {
	isScanAvailable: () =>
		postMessageToNativeApp({
			type: 'SIGUR_IS_SCAN_AVAILABLE'
		}),
	requestPermissions: () =>
		postMessageToNativeApp({
			type: 'SIGUR_REQUEST_PERMISSIONS'
		}),
	startAccess: () =>
		postMessageToNativeApp({
			type: 'SIGUR_START_ACCESS'
		}),
	stopAccess: () =>
		postMessageToNativeApp({
			type: 'SIGUR_STOP_ACCESS'
		}),
}

export const locationService: BridgeLocationService = {
	isReady: () =>
		postMessageToNativeApp({
			type: 'LOCATION_SERVICE_IS_READY'
		}),
	requestIndoorPermissions: () =>
		postMessageToNativeApp({
			type: 'LOCATION_SERVICE_REQUEST_INDOOR_PERMISSION'
		}),
	requestGpsPermissions: () =>
		postMessageToNativeApp({
			type: 'LOCATION_SERVICE_REQUEST_GPS_PERMISSION'
		}),
	start: () =>
		postMessageToNativeApp({
			type: 'LOCATION_SERVICE_START'
		}),
	stop: () =>
		postMessageToNativeApp({
			type: 'LOCATION_SERVICE_STOP'
		}),
	status: () =>
		postMessageToNativeApp({
			type: 'LOCATION_SERVICE_STATUS'
		}),
}
